@use 'styles/breakpoints' as *;

.carousel {
  display: block;
  overflow: hidden;
}

.slidesContainer {
  position: relative;
}

.slides {
  display: flex;
  margin: auto;
}

.slide {
  flex-shrink: 0;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 calc(var(--carousel-slide-spacing) / 2);
  width: max-content;
  height: 100%;

  &.faded {
    opacity: 0.5;
  }
}

.controls {
  width: 100%;
}

.directionButton {
  position: absolute;
  top: 50%;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.06));
  display: none;

  @include desktop {
    display: flex;
  }

  &.prevButton {
    left: calc(50% - calc(var(--carousel-button-spacing) / 2));
    translate: -100% -50%;
  }

  &.nextButton {
    left: calc(50% + calc(var(--carousel-button-spacing) / 2));
    translate: 0 -50%;
  }
}

.controlButtons {
  margin: auto;
  display: flex;
  justify-items: stretch;
  width: var(--carousel-controls-width);
  margin-top: var(--carousel-controls-margin);
}

.controlButton {
  background-color: var(--clr-dark-gray-faded);
  height: 0.5rem;
  width: 100%;

  &.active {
    background-color: var(--clr-dark-gray);
  }
}
