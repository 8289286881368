@use 'styles/breakpoints' as *;

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 15rem;

  @include desktop {
    height: 24.6875rem;
  }

  &::before {
    background-color: var(--clr-light-gray);
    content: '';
    position: absolute;
    --morph-size: 0%;
    inset: 0;
    width: 100%;
    height: 100%;
    transition: all var(--transition-duration) ease;
    z-index: -1;
    clip-path: polygon(
      100% calc(0% + calc(var(--morph-size)) * 1),
      0% 0%,
      0% 100%,
      100% calc(100% - calc(var(--morph-size)) * 1)
    );
  }

  &.hasPage {
    &:hover {
      &::before {
        --morph-size: 5%;
        inset: calc(-0.5 * var(--morph-size)) 0;
        height: calc(100% + calc(1 * var(--morph-size)));
      }
    }
  }
}

.logo {
  flex: 1;
  align-self: center;
  width: 10rem;
  object-fit: contain;
  margin: 0 3rem;

  @include desktop {
    width: 15rem;
    margin: 0 4.875rem;
  }
}

.footer {
  display: grid;
  grid-template:
    'name .' 1fr
    'jobTitle arrow' 1fr /
    1fr auto;
  height: 3.75rem;
  padding: 0 1rem 1rem 1rem;
  row-gap: 0.125rem;

  @include desktop {
    height: 5rem;
    padding: 0 1.75rem 1.75rem 1.75rem;
    row-gap: 0.25rem;
  }
}

.name {
  grid-area: name;
}

.jobTitle {
  grid-area: jobTitle;
}

.arrow {
  grid-area: arrow;

  width: 1.625rem;
  height: 1.25rem;

  @include desktop {
    width: 2.75rem;
    height: 1.75rem;
  }
}
