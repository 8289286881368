@use 'styles/breakpoints' as *;

.container {
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 3rem;

  @include desktop {
    margin: auto;
    display: grid;
    grid-template-columns: 35rem 40rem;
    gap: 5rem;
  }
}

.header {
  align-self: start;
  padding: 0 2rem;

  @include desktop {
    position: sticky;
    top: 10vh;
    padding: 0rem;
  }
}

.scollContent {
  display: flex;
  gap: 1rem;
  padding: 2.5rem 2rem;
  margin: -2.5rem 0;
  overflow-x: scroll;
  scrollbar-width: 0px;

  &::-webkit-scrollbar {
    display: none;
  }

  @include desktop {
    overflow: visible;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin: 0;
    padding: 0rem;
  }
}

.button {
  width: fit-content;
}
