@use 'styles/breakpoints' as *;

.grid {
  display: grid;
  gap: 2rem;
  justify-items: center;
  justify-content: center;
  grid-template-columns: repeat(1, 100%);

  @include desktop {
    gap: 1.5rem;
    grid-template-columns: repeat(4, 24.75rem);
  }
}

.item {
  width: 100%;
  height: var(--item-height);
  position: relative;

  @include desktop {
    width: unset;
  }
}
