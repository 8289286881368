@use 'styles/breakpoints' as *;

.marquee {
  --marquee-gap: 2rem;

  @include desktop {
    --marquee-gap: 7rem;
  }
}

.marqueeItem {
  width: 6rem;
  height: auto;

  @include desktop {
    width: 10rem;
    height: auto;
  }
}

.clientLogo {
  background-color: transparent;
  object-position: center;
  object-fit: contain;
}
