@use 'styles/breakpoints' as *;

.contactCapi {
  --intro-margin: 1rem;

  @include desktop {
    --intro-margin: 2.5rem;
  }
}

.image {
  aspect-ratio: 6/7;
  position: relative;
  width: 100%;
  img {
    object-fit: cover;
  }
}

.contactInfo {
  display: grid;
  justify-items: start;
  align-self: center;
}

.intro {
  margin-bottom: var(--intro-margin);
}
