@use 'styles/breakpoints' as *;

.marquee {
  position: relative;
  display: flex;
  overflow-x: hidden;
  gap: var(--marquee-gap);
  --mask-width: 6%;
  mask-image: linear-gradient(
    to right,
    transparent 0%,
    #000 calc(0% + var(--mask-width)),
    #000 calc(100% - var(--mask-width)),
    transparent 100%
  );
}

.marqueeContent {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--marquee-gap);
  min-width: 100%;
  animation: scroll 30s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--marquee-gap)));
  }
}
